import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <p>Copyright © VERMA GmbH 2025. All rights reserved.    <Link to="/career" className="footer-link">{('Career')}</Link>     <Link to="/privacy-policy" className="footer-link">{('Privacy Policy')}</Link>
      </p>
       
    </footer>
  );
};

export default Footer;
