import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Products.css';
import BiometricFace from '../images/Biometric-face.jpg';
import CCTV from '../images/cctv.jpg';
import Services from '../images/services.jpg';
import ScrumBot from '../images/scrumbot.png';

const Products = () => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null);

  const toggleDescription = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const products = [
    { img: BiometricFace, title: t('digiPass'), description: t('digiPassDescription') },
    { img: CCTV, title: t('computerVision'), description: t('cctvIntelligenceDescription') },
    { img: ScrumBot, title: t('ScrumBotandHotelAssistant'), description: t('ScrumBotandHotelAssistantDescription') },
    { img: Services, title: t('jobAutomation'), description: t('jobAutomationDescription') }
    
  ];

  return (
    <div className="products">
      <h1 style={{ textAlign: "center" }}>{t('ourProducts')}</h1>
      <div className="product-list">
        {products.map((product, index) => (
          <div className="product-item" key={index}>
            <img src={product.img} alt={product.title} />
            <h3>{product.title}</h3>
            <button className="toggle-button" onClick={() => toggleDescription(index)}>
              {openIndex === index ? '▲ Hide' : '▼ Show'}
            </button>
            {openIndex === index && <p className="product-description">{product.description}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
