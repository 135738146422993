import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './Career.css';

const Career = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="career-page">
      <header className="career-header">
        <h1>{t('career.title')}</h1>
        <p>{t('career.subtitle')}</p>
      </header>

      <section className="career-content">
        <p>{t('career.description')}</p>
        <button className="career-button" onClick={() => window.open('https://www.linkedin.com/company/71014967/admin/dashboard/', '_blank')}>
          {t('career.viewJobs')}
        </button>
      </section>
    </div>
  );
};

export default Career;
