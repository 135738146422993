import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Products from './pages/Products';
import Team from './pages/Team';
import Contact from './pages/Contact';
import SkilledProfessionals from './pages/skilledprofessionals';
import './styles.css';
import LanguageSwitcher from './components/LanguageSwitcher';
import Footer from './components/Footer'; // Import Footer component
import logo from './images/Logo-side.png';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Career from './pages/Career';

const App = () => {
  const { t } = useTranslation();

  return (
    <Router>
      <div className="app">
        <nav className="navbar">
          <div className="logo-container">
            <Link to="/">
              <img src={logo} alt="Company Logo" className="logo" />
            </Link>
          </div>
          <ul className="nav-links">
            <li><Link to="/">{t('Home')}</Link></li>
            <li><Link to="/about">{t('about')}</Link></li>
            <li><Link to="/services">{t('services')}</Link></li>
            <li><Link to="/SkilledProfessionals">{t('SkilledProfessionals')}</Link></li>
            <li><Link to="/products">{t('products')}</Link></li>
            <li><Link to="/team">{t('team')}</Link></li>
            <li><Link to="/contact">{t('contact')}</Link></li>
          </ul>
          <div className="language-switcher">
            <LanguageSwitcher />
          </div>
        </nav>

        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} /> 
            <Route path="/services" element={<Services />} />
            <Route path="/products" element={<Products />} />
            <Route path="/SkilledProfessionals" element={<SkilledProfessionals />} />
            <Route path="/team" element={<Team />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* New Privacy Policy Route */}
            <Route path="/career" element={< Career/>} />
          </Routes>
        </div>

        <Footer /> {/* Footer positioned after content */}
      </div>
    </Router>
  );
};

export default App;
