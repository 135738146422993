import React from "react";
import { useTranslation } from "react-i18next";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-container">
      <h1>{t("privacyPolicy.title")}</h1>

      <section>
        <h2>{t("privacyPolicy.introduction")}</h2>
       {/*  <p>{t("privacyPolicy.introductionText")}</p> */}
      </section>

      <section>
        <h2>{t("privacyPolicy.whoWeAre")}</h2>
        <p>{t("privacyPolicy.whoWeAreEU")}</p>
        <p>{t("privacyPolicy.whoWeAreIndia")}</p>
        <p>{t("privacyPolicy.contactInformation")}</p>
      </section>

      <section>
        <h2>{t("privacyPolicy.personalDataCollected")}</h2>
        <h3>{t("privacyPolicy.informationYouProvideDirectly")}</h3>
        <ul>
          {t("privacyPolicy.informationYouProvideDirectlyList", { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <h3>{t("privacyPolicy.informationWeCollectAutomatically")}</h3>
        <ul>
          {t("privacyPolicy.informationWeCollectAutomaticallyList", { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </section>

      <section>
        <h2>{t("privacyPolicy.howWeUsePersonalData")}</h2>
        <ul>
          {t("privacyPolicy.howWeUsePersonalDataList", { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </section>

      <section>
        <h2>{t("privacyPolicy.legalBasesForProcessing")}</h2>
        <ul>
          {t("privacyPolicy.legalBasesForProcessingList", { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </section>

      <section>
        <h2>{t("privacyPolicy.dataSharing")}</h2>
        <ul>
          {t("privacyPolicy.dataSharingList", { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </section>

      <section>
        <h2>{t("privacyPolicy.internationalDataTransfers")}</h2>
        <p>{t("privacyPolicy.internationalDataTransfersText")}</p>
      </section>

      <section>
        <h2>{t("privacyPolicy.dataRetention")}</h2>
        <p>{t("privacyPolicy.dataRetentionText")}</p>
      </section>

      <section>
        <h2>{t("privacyPolicy.yourRights")}</h2>
        <ul>
          {t("privacyPolicy.yourRightsList", { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>{t("privacyPolicy.exercisingYourRights")}</p>
      </section>

      <section>
        <h2>{t("privacyPolicy.dataSecurity")}</h2>
        <p>{t("privacyPolicy.dataSecurityText")}</p>
      </section>

      <section>
        <h2>{t("privacyPolicy.childrensPrivacy")}</h2>
        <p>{t("privacyPolicy.childrensPrivacyText")}</p>
      </section>

      <section>
        <h2>{t("privacyPolicy.changesToThisPolicy")}</h2>
        <p>{t("privacyPolicy.changesToThisPolicyText")}</p>
      </section>

      <section>
        <h2>{t("privacyPolicy.contactUs")}</h2>
        <p>{t("privacyPolicy.contactUsText")}</p>
      </section>

      <section>
        <h2>{t("privacyPolicy.dpdpSpecificItems")}</h2>
        <ul>
          <li>{t("privacyPolicy.dpdpSpecificItems1")}</li>
          <li>{t("privacyPolicy.dpdpSpecificItems2")}</li>
          <li>{t("privacyPolicy.dpdpSpecificItems3")}</li>
          <li>{t("privacyPolicy.dpdpSpecificItems4")}</li>
        </ul>
      </section>

      <p>{t("privacyPolicy.finalNote")}</p>
    </div>
  );
};

export default PrivacyPolicy;

