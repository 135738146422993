
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';  
import './Services.css';
import services from '../images/services.png';

const Services = () => {
  const { t } = useTranslation();  

  // State for each service to toggle description visibility
  const [isAiConsultingVisible, setAiConsultingVisible] = useState(false);
  const [isCustomAISolutionsVisible, setCustomAISolutionsVisible] = useState(false);
  const [isAIDigitalProductVisible, setAIDigitalProductVisible] = useState(false);

  return (
    <div className="services">
      <h1 style={{ textAlign: "center" }}>{t('ourServices')}</h1>  
      <img src={services} alt="Skilled Immigration Process" className="service-image" />

      <div className="service-items">
        {/* AI Consulting */}
        <div className="service-item">
          <div className="service-header" onClick={() => setAiConsultingVisible(!isAiConsultingVisible)}>
            <h3>{t('aiConsulting')}</h3>  
            <button className="arrow-button">
              {isAiConsultingVisible ? '▲' : '▼'}
            </button>
          </div>
          {isAiConsultingVisible && <p className="service-description">{t('aiConsultingDescription')}</p>}
        </div>

        {/* Custom AI Solutions */}
        <div className="service-item">
          <div className="service-header" onClick={() => setCustomAISolutionsVisible(!isCustomAISolutionsVisible)}>
            <h3>{t('customAISolutions')}</h3>  
            <button className="arrow-button">
              {isCustomAISolutionsVisible ? '▲' : '▼'}
            </button>
          </div>
          {isCustomAISolutionsVisible && <p className="service-description">{t('customAISolutionsDescription')}</p>}
        </div>

        {/* AI Digital Product */}
        <div className="service-item">
          <div className="service-header" onClick={() => setAIDigitalProductVisible(!isAIDigitalProductVisible)}>
            <h3>{t('AIDigitalproduct')}</h3>  
            <button className="arrow-button">
              {isAIDigitalProductVisible ? '▲' : '▼'}
            </button>
          </div>
          {isAIDigitalProductVisible && <p className="service-description">{t('globalReachDescription')}</p>}
        </div>
      </div>
    </div>
  );
};

export default Services;
